import { Dispatch, ReactElement, SetStateAction, useCallback, useMemo } from "react";
import Reconciliation, { ReconciliationProps } from "./Reconciliation";
import { AnalyticsEvent, AnalyticsEventsMeta, EventAnalyticsState, Leader, AnalyticsETLLogAction } from "../../store";

import CabinetPage from "../../components/Common/CabinetPage";
import { CabModal } from "@CabComponents/CabModal";
import { 
  CreateEventCategoryModal
} from "../../components/EventAnalytics/Modals/CreateEventCategoryModal/CreateEventCategoryModal";
import CreateEventTypeModal from "../../components/EventAnalytics/Modals/CreateEventTypeModal/CreateEventTypeModal";
import { CabButton } from "@CabComponents/CabButton";
import CabSpinner from "@CabComponents/CabSpinner";
import { Control } from "react-hook-form";
import { getLocalTimeZone } from "../../utils/scheduleUtils";
import { EventLabelAbstractFormInput} from "../../components/EventAnalytics/Modals/EventLabelAbstractModal";
import { PAGE_URL } from "../../constants";
import { CabTooltip } from "@CabComponents/CabTooltip";
import { CabIcon } from "@CabComponents/CabIcon";
import { IoReloadOutline } from 'react-icons/io5';
import { DateTime } from "luxon";
import { debounce } from "lodash-es";
import { useNavigate } from "react-router";
import { Box, Typography } from "@mui/material";
import FetchInProgress from "../../components/EventAnalytics/FetchInProgress/FetchInProgress";
import colors from "../../colors";


type AnalyticsReconciliationProps = Omit<ReconciliationProps, "onLeaderSelect" | "onSearchInputChange"> & {
  cancelCreateLabel: (callback: () => void) => void
  setEventForCreateCategory: Dispatch<SetStateAction<Partial<AnalyticsEvent> | null>>
  control: Control<EventLabelAbstractFormInput>
  eventForCreateType: Partial<AnalyticsEvent> | null
  createLabel: (action: "event_type" | "event_category", callback: () => void) => void
  setEventForCreateType: Dispatch<SetStateAction<Partial<AnalyticsEvent> | null>>
  eventForCreateCategory: Partial<AnalyticsEvent> | null
  recurringMeetingData: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'> | null
  setRecurringMeetingData: Dispatch<SetStateAction<Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'> | null>>
  confirmRecurringMeetingChange: (
    event: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'> | null, updateAll: boolean
  ) => void
  handleSearchInputChange: (value: string | null) => void
  handleSetLeader: (leader: Leader | null) => void
  recurrence_types: EventAnalyticsState["recurrence_types"]
  updateEvent: (event: Partial<AnalyticsEvent> & Pick<AnalyticsEvent, 'id'>) => void,
  isValid: boolean;
  exportData: {[key: number]: AnalyticsEvent} | undefined;
  meta: AnalyticsEventsMeta
  handleChangeDates: (startDate: DateTime | undefined, endDate: DateTime | undefined) => void
  onRequestResyncData: () => void;
  resyncDisabled: boolean;
  etlStatus?: AnalyticsETLLogAction;
  initialLoading: boolean;
  isTrialing: boolean;
  page: number;
};

const AnalyticsReconciliation = ({
  myLeaders, minDate, cancelCreateLabel, setEventForCreateCategory,
  control, eventForCreateType, createLabel, setEventForCreateType, eventForCreateCategory,
  recurringMeetingData, setRecurringMeetingData, confirmRecurringMeetingChange, updateEvent, 
  start, end, user, event_types, event_categories, executive_contacts,
  maxDate, selectedLeader, consolidated_locations, loading, page, totalRows,
  rows, handlePageChange, handleSearchInputChange, handleFilterModelChange, handleSortModelChange,
  handleSetLeader, recurrence_types, isValid, exportData, fetchAnalyticsReconciliationExport,
  resyncFetched, meta, analysis_inclusions, handleChangeDates, onRequestResyncData, resyncDisabled,
  etlStatus, initialLoading, isTrialing
}: AnalyticsReconciliationProps): ReactElement => {
  const navigate = useNavigate();

  const onLeaderSelect = useCallback((leaderId: number | null) =>
    handleSetLeader(myLeaders.find(leader => leader.id === leaderId) || null), [handleSetLeader, myLeaders]);

  const onSearchInputChange = useMemo(() => {
    return debounce(handleSearchInputChange, 1500);
  }, [handleSearchInputChange]);

  return <>
    <CabinetPage
      pageName={'Reconciliation'}
      headerContent={
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
          <Typography variant="h1">
            Reconciliation
          </Typography>
          <CabTooltip wrapWithSpan title={
            resyncDisabled ? 
              isTrialing ?  <Typography
                sx={{color: colors.white800, cursor: "pointer"}}
                onClick={() => navigate(PAGE_URL.SUBSCRIPTION)}
              >
                Click to purchase a license to refetch your data.
              </Typography>
                : 
                "Requesting a resync will be available after your last fetch has completed" : ""
          }>
            <CabButton 
              onClick={onRequestResyncData}
              icon={<CabIcon Icon={IoReloadOutline}/>}
              sx={{ marginBottom: 1 }}
              disabled={resyncDisabled}
            >
              Resync
            </CabButton>
          </CabTooltip>
        </Box>
      }
    >
      {((
        (etlStatus === AnalyticsETLLogAction.STARTED || etlStatus === AnalyticsETLLogAction.QUEUED ||
        etlStatus === AnalyticsETLLogAction.NOT_FETCHED
        ) && (maxDate === null && minDate === null)) ? (
          <FetchInProgress etlStatus={etlStatus} />
        ) : (
          <Box position="relative" height="100%">
            <Reconciliation
              resyncFetched={resyncFetched}
              myLeaders={myLeaders}
              onLeaderSelect={onLeaderSelect}
              selectedLeader={selectedLeader}
              recurrence_types={recurrence_types}
              selectPlaceholder="Select a Teammate"
              onSearchInputChange={onSearchInputChange}
              event_categories={event_categories}
              event_types={event_types}
              consolidated_locations={consolidated_locations}
              rows={rows}
              executive_contacts={executive_contacts}
              loading={loading}
              handlePageChange={handlePageChange}
              page={page}
              totalRows={totalRows}
              handleSortModelChange={handleSortModelChange}
              handleFilterModelChange={handleFilterModelChange}
              start={start}
              end={end}
              handleChangeDates={handleChangeDates}
              user={user}
              handleUpdateEvent={updateEvent}
              tz={getLocalTimeZone()?.name}
              maxDate={maxDate}
              minDate={minDate}
              exportData={exportData}
              meta={meta}
              fetchAnalyticsReconciliationExport={fetchAnalyticsReconciliationExport}
              analysis_inclusions={analysis_inclusions}
            />

            {loading && !initialLoading &&
                <Box 
                  sx={{
                    width: "100%",
                    height: "100%",
                    background: "#fafafa",
                    opacity: 0.7,
                    zIndex: 100
                  }}
                  position="absolute"
                  display="block"
                  top={0}
                  left={0}
                >
                  <Box
                    position="fixed"
                    left="50%"
                    top="50%"
                    sx={{transform: "translate(-50%, -50%)"}}
                  >
                    <CabSpinner scale={4} color='inherit' />
                  </Box>
                </Box>
            }
          </Box>
        )
      )}
    </CabinetPage>

    <CabModal
      open={!!eventForCreateCategory}
      onClose={() => setEventForCreateCategory(null)}
      closeIcon={true}
      title="Create New Event Category"
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={() => cancelCreateLabel(() => setEventForCreateCategory(null))}
        >
          Cancel
        </CabButton>
        <CabButton
          onClick={() => createLabel("event_category", () => setEventForCreateCategory(null))}
          disabled={!isValid}
        >
          Create Category
        </CabButton>
      </>}
    >
      <CreateEventCategoryModal
        control={control}
      />
    </CabModal>
    <CabModal
      open={!!eventForCreateType}
      onClose={() => setEventForCreateType(null)}
      closeIcon={true}
      title="Create New Event Type"
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={() => cancelCreateLabel(() => setEventForCreateType(null))}
        >
          Cancel
        </CabButton>
        <CabButton
          onClick={() => createLabel("event_type", () => setEventForCreateType(null))}
          disabled={!isValid}
        >
          Create Type
        </CabButton>
      </>}
    >
      <CreateEventTypeModal
        control={control}
      />
    </CabModal>
    <CabModal
      open={!!recurringMeetingData}
      onClose={() => setRecurringMeetingData(null)}
      actionButtons={<>
        <CabButton
          buttonType="tertiary"
          onClick={() => cancelCreateLabel(() => setRecurringMeetingData(null))}
        >
          Cancel
        </CabButton>
        <CabButton
          onClick={() => confirmRecurringMeetingChange(recurringMeetingData, false)}
        >
          Update This Event
        </CabButton>
        <CabButton
          onClick={() => confirmRecurringMeetingChange(recurringMeetingData, true)}
        >
          Update Series
        </CabButton>
      </>}
    >
      <Box>
        This is a recurring event. Do you want to {
          (recurringMeetingData && recurringMeetingData["inclusion"]) ? "ignore" : "update"} all events in 
        this series, or just this specific event?
      </Box>
    </CabModal>
  </>;
};

export default AnalyticsReconciliation;
